import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage } from "gatsby-plugin-image";
import SignupButton from "../SignupButton.js";

export default function ArtistDevelopmentSection4({
  s4Heading,
  s4Subheading,
  s4IconsTitles,
  externalSalesUrl,
}) {
  return (
    <Styling>
      <div className="main-content">
        <h2 className="mainHeading">{s4Heading}</h2>
        <div className="text">{s4Subheading}</div>
        <ul className="list">
          {s4IconsTitles.map((item) => (
            <li>
              <div className="icon">
                <GatsbyImage image={item.icon.localFile.childImageSharp.gatsbyImageData} />
              </div>
              <div className="text">
                <h3>{item.title}</h3>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <SignupButton externalSalesUrl={externalSalesUrl} />
    </Styling>
  );
}
