import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage } from "gatsby-plugin-image";
import SignupButton from "../SignupButton.js";

export default function ArtistDevelopmentSection1({
  s1MainHeading,
  s1SubHeading,
  s1LogosHeading,
  s1FeaturedLogos,
  s1ListWIcons,
  externalSalesUrl,
}) {
  return (
    <Styling>
      <div className="featuredOn">
        <div className="featuredHeading">{s1LogosHeading}</div>
        <div className="featuredlogos">
          {s1FeaturedLogos.map((logo) => (
            <GatsbyImage
              image={logo.logo.localFile.childImageSharp.gatsbyImageData}
              objectFit="contain"
            />
          ))}
        </div>
      </div>
      <div className="main-content">
        <h2 className="mainHeading">{s1MainHeading}</h2>
        <div className="text">{s1SubHeading}</div>
        <ul className="list">
          {s1ListWIcons.map((item) => (
            <li>

              <div className="icon">
                <GatsbyImage image={item.item.icon.localFile.childImageSharp.gatsbyImageData} />
              </div>
              <div className="text">
                <h3>{item.item.heading}</h3>
                <p>{item.item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <SignupButton externalSalesUrl={externalSalesUrl} />
    </Styling>
  );
}
