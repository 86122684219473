import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import formatIntructors from "../../../../utils/formatInstructors.js";
import calculateCourseDuration from "../../../../utils/calculateCourseDuration.js";
import { AiOutlineUser, AiOutlineClockCircle } from "react-icons/ai";
import parse from "html-react-parser";
import SnipcartButton from "../../../reusable/SnipcartButton/index.js";
import Play from "../../../../images/play.inline.svg";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import TrustBox from "../TrustBox.js";
import SignupButton from "../SignupButton.js";

export default function ArtistDevelopmentIntro({
  video,
  logo1,
  logo2,
  introVideoObjectFit,
  title,
  introText,
  darkLightMode,
  index,
  externalSalesUrl,
}) {
  const trailerVid = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    if (isPlaying === false) {
      trailerVid.current.play();
      setIsPlaying(true);
    } else {
      trailerVid.current.pause();
      setIsPlaying(false);
    }
  };
  return (
    <Styling
      id={`section_${index + 1}`}
      darkLightMode={darkLightMode}
      introVideoObjectFit={introVideoObjectFit}>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async></script>
      </Helmet>
      <div className="video-wrapper">
        <div className={isPlaying ? `play playing` : `play paused`}>
          <button onClick={() => handlePlayVideo()}>
            <Play />
          </button>
        </div>
        <video playsInline ref={trailerVid} src={video}></video>
      </div>
      <div className="info-right">
        <div className="logos">
          {logo1 && (
            <GatsbyImage
              image={logo1?.localFile?.childImageSharp?.gatsbyImageData}
              alt={logo1.altText}
              objectFit="contain"
            />
          )}
          {logo2 && (
            <GatsbyImage
              image={logo2?.localFile?.childImageSharp?.gatsbyImageData}
              alt={logo2.altText}
              objectFit="contain"
            />
          )}
        </div>
        <div className="info">
          <h1 className="main-title">{parse(title)}</h1>
          {introText !== undefined && <div className="intro-text">{parse(introText)}</div>}
        </div>
        <div className="buttons">
          <SignupButton externalSalesUrl={externalSalesUrl} />
        </div>

        <div className="trustPilot">
          <TrustBox />
        </div>
      </div>
    </Styling>
  );
}
