import React from "react";

export default function SnipcartButton({
  className,
  id,
  price,
  url,
  description,
  image,
  name,
  is_plugin,
  buttonText,
  meta,
  guid,
  categories,
}) {
  //console.log(id);
  if (guid) {
    return (
      <button
        className={`snipcart-add-item capsule_button ${
          className !== undefined
            ? className
            : "white border_hover border_white"
        }`}
        data-item-id={is_plugin === true ? meta : id}
        data-item-price={price}
        data-item-url={url}
        data-item-metadata={
          is_plugin === undefined || is_plugin === null
            ? `{"courseId": ${meta}, "trackQuantities": false, "isPlugin": false}`
            : `{"trackQuantities": false, "isPlugin": true}`
        }
        data-item-categories={categories}
        data-item-file-guid={guid}
        data-item-shippable={false}
        data-item-description={description}
        data-item-image={image}
        data-item-name={name}
      >
        {buttonText}
      </button>
    );
  } else {
    return (
      <button
        className={`snipcart-add-item capsule_button ${
          className !== undefined
            ? className
            : "white border_hover border_white"
        }`}
        data-item-id={is_plugin === true ? meta : id}
        data-item-price={price}
        data-item-url={url}
        data-item-metadata={
          is_plugin === undefined || is_plugin === null
            ? `{"courseId": ${meta}, "trackQuantities": false, "isPlugin": false}`
            : `{"trackQuantities": false, "isPlugin": true}`
        }
        data-item-categories={categories}
        data-item-shippable={false}
        data-item-description={description}
        data-item-image={image}
        data-item-name={name}
      >
        {buttonText}
      </button>
    );
  }
}
