import styled from "styled-components";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: var(--black);
  padding: 8rem 2rem;

  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem;
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    text-align: right;
  }

  .instructors {
    position: relative;
    text-align: left;
  }

  .heading {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    color: var(--white);
    letter-spacing: -0.07em;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 0 auto;
    max-width: 650px;
    text-align: center;
    margin-bottom: 4rem;
    position: absolute;
    bottom: -3rem;
    left: -6rem;
    transform: rotate(180deg);
    writing-mode: vertical-rl;

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      transform: rotate(90deg);

      path {
        fill: var(--white);
      }
    }
  }

  .swiper * {
    pointer-events: none !important;
  }

  .swiper-slide {
    opacity: 0 !important;
    pointer-events: none;
    transition: 0.3s opacity ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    color: var(--white);
    align-items: center;
    gap: 8rem;

    @media only screen and (max-width: 768px) {
      gap: 4rem;
    }

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    svg {
      margin-bottom: 2rem;

      path {
        fill: var(--white);
      }
    }

    &.swiper-slide-active {
      opacity: 1 !important;
      pointer-events: all;

      .buttons {
        pointer-events: all !important;

        .arrow {
          z-index: 20;
          pointer-events: all !important;

          * {
            pointer-events: all !important;
          }
        }
      }
    }

    .swiper-button-disabled {
      opacity: 0.1;
      cursor: default;
    }

    .buttons {
      display: inline-flex;

      .arrow {
        border: 1px solid
          ${(props) =>
            props.darkLightMode === "light" || props.darkLightMode === undefined
              ? "var(--black)"
              : "var(--white)"};
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 1rem;

        ${({ theme }) => theme.easing("0.5")}

        &.next {
          margin-right: 4rem;
        }

        svg {
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          ${({ theme }) => theme.easing("0.5")}
          position: relative;
          path {
            ${({ theme }) => theme.easing("0.5")}
            fill: var(--white);
          }
        }

        &:hover {
          background: var(--white);
          svg path {
            fill: var(--black);
          }
        }
      }
    }

    .name {
      text-transform: uppercase;
      ${({ theme }) => theme.tomato("regular")}
      text-transform:uppercase;
      letter-spacing: -0.07em;
      font-size: 2.5rem;
      line-height: 2.5rem;
      margin: 0 auto 1rem auto;
      /* max-width: 950px; */
      text-align: left;
      margin-bottom: 2rem;
      position: relative;

      strong {
        ${({ theme }) => theme.tomato("bold")}
      }
    }

    .bio {
      position: relative;
      margin-bottom: 4rem;

      p {
        line-height: 1.5em;
        margin-bottom: 1rem;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .pagination {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;

    button {
      color: var(--white);
      border: 0;
      background: transparent;
      padding: 0;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 1.2rem;
      text-underline-offset: 5px;

      &.active {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
`;

export { Styling };
