import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Play from "../../../../images/play.inline.svg";
import Arrow from "../../../../images/arrow_right_black.inline.svg";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import SwiperCore, { Pagination, Navigation, EffectFade, Controller, Autoplay } from "swiper/core";
import SignupButton from "../SignupButton.js";

SwiperCore.use([Pagination, Navigation, EffectFade, Controller, Autoplay]);

export default function ArtistDevelopmentSection5({ s5ContentRepeater, externalSalesUrl }) {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <Styling>
      <div className="wrapper">
        <section className="features">
          <Swiper
            slidesPerView={1}
            className="swiper-1"
            loop={false}
            modules={[EffectFade]}
            draggable={false}
            autoHeight={true}
            effect="fade"
            onSwiper={setFirstSwiper}
            // controller={{ control: secondSwiper }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onActiveIndexChange={(swiper) => {
              setCurrentIndex(swiper.activeIndex);
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: true,
            // }}
          >
            {s5ContentRepeater.map((test, index) => {
              return (
                <SwiperSlide>
                  <div className="left">
                    <div className="content">
                      <h2>
                        <Arrow />
                        {parse(test?.s5Heading)}
                      </h2>
                      <div className="text">{parse(test?.s5Text)}</div>
                      <SignupButton externalSalesUrl={externalSalesUrl} />
                    </div>
                  </div>
                  <div className="right">
                    <GatsbyImage
                      image={getImage(test?.s5Image.localFile)}
                      alt={test?.s5Image.altText}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
        <div className="pagination-wrapper">
          <label>Find out more about how we:</label>
          <div className="pagination">
            {s5ContentRepeater.map((content, index) => {
              return (
                <button
                  onClick={() => {
                    firstSwiper.slideTo(index);
                  }}
                  className={`pagination-bullet ${index === currentIndex ? "active" : ""}`}
                  aria-label={`Slide ${index + 1}`}
                  key={index}>
                  {content.s5PaginationLabel}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </Styling>
  );
}
