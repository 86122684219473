import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";

export default function ArtistDevelopmentSection7({ s7Heading, s7VideosRepeater }) {
  return (
    <Styling>
      <h2 className="heading">{s7Heading}</h2>
      <div className="wrapper">
        <div className="videos">
          {s7VideosRepeater.map((video, index) => {
            return (
              <article className="video">
                <video controls>
                  <source src={video.video} type="video/mp4" />
                </video>
                <div className="text">
                  <h3>{video.heading}</h3>
                  <div class="text-content">{parse(video.text)}</div>
                </div>
              </article>
            );
          })}
        </div>
      </div>
    </Styling>
  );
}
