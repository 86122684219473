import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Play from "../../../../images/play.inline.svg";
import Arrow from "../../../../images/arrow_right_black.inline.svg";
import Quotes from "../../../../images/quotes.inline.svg";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import SwiperCore, { Pagination, Navigation, EffectFade, Controller, Autoplay } from "swiper/core";

SwiperCore.use([Pagination, Navigation, EffectFade, Controller, Autoplay]);

export default function ArtistDevelopmentSection2({ testimonialsData }) {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <Styling darkLightMode={"dark"}>
      <div className="wrapper">
        <h2 className="heading">
          <Arrow /> What our students say
        </h2>
        <section className="testimonials">
          <Swiper
            slidesPerView={1}
            className="swiper-1"
            loop={false}
            modules={[EffectFade]}
            draggable={false}
            effect="fade"
            onSwiper={setFirstSwiper}
            // controller={{ control: secondSwiper }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onActiveIndexChange={(swiper) => {
              setCurrentIndex(swiper.activeIndex);
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            autoplay={{
              delay: 8000,
              disableOnInteraction: true,
            }}>
            {testimonialsData.map((test, index) => {
              return (
                <SwiperSlide>
                  <div className="left">
                    <Quotes className="quotes" />
                    <div className="quote">{test.quote}</div>
                    <div className="person">-{test?.personName}</div>
                    <div className="buttons">
                      <button
                        ref={prevRef}
                        onClick={(swiper) => {
                          firstSwiper.slideTo(index - 1);
                        }}
                        className="arrow prev"
                        aria-label="Previous">
                        <BsArrowLeft />
                      </button>
                      <button
                        ref={nextRef}
                        onClick={(swiper) => {
                          firstSwiper.slideTo(index + 1);
                        }}
                        className="arrow next"
                        aria-label="Next">
                        <BsArrowRight />
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    {/* <Play className="play" /> */}
                    <GatsbyImage
                      image={getImage(test?.thumbnailImage.localFile)}
                      alt={test?.thumbnailImage.altText}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
        <div className="pagination">
          {testimonialsData.map((test, index) => {
            return (
              <button
                onClick={() => {
                  firstSwiper.slideTo(index);
                }}
                className={`pagination-bullet ${index === currentIndex ? "active" : ""}`}
                aria-label={`Slide ${index + 1}`}
                key={index}>
                {test.personName}
              </button>
            );
          })}
        </div>
      </div>
    </Styling>
  );
}
