import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: var(--white);
  padding: 4rem 2rem;

  @media only screen and (min-width: 1024px) {
    padding: 8rem 2rem;
  }

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.06);
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  h2 {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    letter-spacing: -0.07em;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 950px;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
      transform: scaleX(-1) rotate(90deg);
      path {
        fill: var(--white);
      }
    }
  }

  .info {
    display: grid;
    gap: 2rem;
  }

  .info-item {
    display: grid;

    gap: 4rem;
    margin: 0 auto;
    max-width: 1024px;

    @media only screen and (min-width: 768px) {
      grid-template-columns: 1.5fr 2.5fr;
    }

    .image {
      width: 100%;

      .gatsby-image-wrapper {
        width: 100%;
      }
    }

    .detail {
      position: relative;
      padding: 0 4rem 2rem 4rem;

      @media only screen and (min-width: 768px) {
        padding: 4rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      svg {
        width: 20px;
        height: 20px;
        position: absolute;
        transform: translateX(calc(-100% - 1rem));
      }

      h3 {
        text-transform: uppercase;
        ${({ theme }) => theme.tomato("regular")}
        text-transform:uppercase;
        letter-spacing: -0.07em;
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin: 0 auto 1rem auto;
        /* max-width: 950px; */
        text-align: left;
        margin-bottom: 2rem;
        position: relative;

        strong {
          ${({ theme }) => theme.tomato("bold")}
        }
      }
      .text {
        p {
          line-height: 1.5em;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &:last-child .detail {
      padding-bottom: 0;
    }
  }
`;

export { Styling };
