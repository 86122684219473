import React from "react";
import { graphql } from "gatsby";
import ArtistDevelopmentIntro from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentIntro";
import ArtistDevelopmentSection1 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection1";
import ArtistDevelopmentSection2 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection2";
import ArtistDevelopmentSection3 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection3";
import ArtistDevelopmentSection4 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection4";
import ArtistDevelopmentSection5 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection5";
import ArtistDevelopmentSection6 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection6";
import ArtistDevelopmentSection7 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection7";
import ArtistDevelopmentSection8 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection8";
import ArtistDevelopmentSection9 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection9";
import ArtistDevelopmentSection10 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection10";
import ArtistDevelopmentSection13 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection13";
import ArtistDevelopmentSection12 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection12";
import ArtistDevelopmentSection11 from "../../components/page-components/ArtistDevelopmentVoltageCircus/ArtistDevelopmentSection11";

export default function ArtistDevelopment({ data }) {
  const { seo, pageData } = data.wpPage;

  return (
    <>
      <ArtistDevelopmentIntro
        video={pageData.videoUrl}
        logo1={pageData.logo1}
        logo2={pageData.logo2}
        introVideoObjectFit={"cover"}
        title={pageData.heading}
        introText={pageData.introText}
        darkLightMode={"dark"}
        index={1}
        externalSalesUrl={pageData.externalSalesUrl}
      />
      <ArtistDevelopmentSection1
        externalSalesUrl={pageData.externalSalesUrl}
        s1MainHeading={pageData.s1MainHeading}
        s1SubHeading={pageData.s1SubHeading}
        s1LogosHeading={pageData.s1LogosHeading}
        s1FeaturedLogos={pageData.s1FeaturedLogos}
        s1ListWIcons={pageData.s1ListWIcons}
      />
      <ArtistDevelopmentSection2 testimonialsData={pageData.s2Testimonials} />
      <ArtistDevelopmentSection3
        s3Heading={pageData.s3Heading}
        s3IconsTitles={pageData.s3IconsTitles}
        s3Text={pageData.s3Text}
      />
      <ArtistDevelopmentSection4
        s4Heading={pageData.s4Heading}
        s4Subheading={pageData.s4Subheading}
        s4IconsTitles={pageData.s4IconsTitles}
        externalSalesUrl={pageData.externalSalesUrl}
      />
      <ArtistDevelopmentSection5
        s5ContentRepeater={pageData.s5ContentRepeater}
        externalSalesUrl={pageData.externalSalesUrl}
      />
      <ArtistDevelopmentSection6
        s6Heading={pageData.s6Heading}
        s6DisableContentToggling={pageData.s6DisableContentToggling}
        s6CourseContentRepeater={pageData.s6CourseContentRepeater}
        externalSalesUrl={pageData.externalSalesUrl}
      />
      <ArtistDevelopmentSection7
        s7Heading={pageData.s7Heading}
        s7VideosRepeater={pageData.s7VideosRepeater}
      />
      <ArtistDevelopmentSection8
        s8Heading={pageData.s8Heading}
        s8IconsTitles={pageData.s8IconsTitles}
      />
      <ArtistDevelopmentSection9
        s9Heading={pageData.s9Heading}
        s9Text={pageData.s9Text}
        s9BackgroundImage={pageData.s9BackgroundImage}
        externalSalesUrl={pageData.externalSalesUrl}
      />
      <ArtistDevelopmentSection10
        s10Heading={pageData.s10Heading}
        s10Repeater={pageData.s10Repeater}
      />
      <ArtistDevelopmentSection11
        s11StaffRepeater={pageData.s11StaffRepeater}
        externalSalesUrl={pageData.externalSalesUrl}
      />
      <ArtistDevelopmentSection12
        s12Heading={pageData.s12Heading}
        s12FaqsRepeater={pageData.s12FaqsRepeater}
      />
      <ArtistDevelopmentSection13
        s13Heading={pageData.s13Heading}
        s13Preheading={pageData.s13Preheading}
        s13Text={pageData.s13Text}
        s13BackgroundImage={pageData.s13BackgroundImage}
        externalSalesUrl={pageData.externalSalesUrl}
      />
    </>
  );
}

export const query = graphql`
  {
    wpPage(title: { eq: "Artist Development – Voltage Circus x TA" }) {
      seo {
        metaDesc
        title
      }
      pageData: acf_artist_development_course {
        # Hero
        heading
        introText
        videoUrl
        externalSalesUrl
        logo1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        logo2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        # Section 1
        s1MainHeading
        s1SubHeading
        s1LogosHeading
        s1FeaturedLogos {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        s1ListWIcons {
          item {
            heading
            text
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }

        # Section 2
        s2Testimonials {
          personName
          quote
          videoEmbedCode
          thumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        # Section 3
        s3Heading
        s3IconsTitles {
          title
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        s3Text

        # Section 4
        s4Heading
        s4Subheading
        s4IconsTitles {
          title
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        # Section 5
        s5ContentRepeater {
          s5Heading
          s5Text
          s5Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          s5PaginationLabel
        }

        # Section 6
        s6Heading
        s6DisableContentToggling
        s6CourseContentRepeater {
          courseSectionTitle
          courseSection {
            courseModuleTitle
            courseModuleText
            courseModuleImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            courseModuleVideo
          }
        }

        # Section 7
        s7Heading
        s7VideosRepeater {
          heading
          text
          video
        }

        # Section 8
        s8Heading
        s8IconsTitles {
          title
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        # Section 9
        s9Heading
        s9Text
        s9BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        # Section 10
        s10Heading
        s10Repeater {
          heading
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        # Section 11
        s11StaffRepeater {
          name
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        # Section 12
        s12Heading
        s12FaqsRepeater {
          question
          answer
        }

        # Section 13
        s13Heading
        s13Preheading
        s13Text
        s13BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
