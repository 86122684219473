import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: rgba(0, 0, 0, 0.06);
  padding: 16rem 2rem;
  background: var(--black);
  color: var(--white);

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* padding: 0 2rem; */
    /* display: grid;
    column-gap: 4rem;
    grid-template-columns: auto 2fr; */
  }

  h2 {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    letter-spacing: -0.07em;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 950px;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
      transform: scaleX(-1) rotate(90deg);
      path {
        fill: var(--white);
      }
    }
  }

  .text {
    max-width: 680px;
    margin: 0 auto 4rem auto;
    text-align: center;

    p {
      line-height: 1.5em;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.3;

    .gatsby-image-wrapper,
    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export { Styling };
