import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import SignupButton from "../SignupButton.js";

export default function ArtistDevelopmentSection9({
  s9Heading,
  s9Text,
  s9BackgroundImage,
  externalSalesUrl,
}) {
  return (
    <Styling>
      <div className="wrapper">
        <h2 className="heading">{s9Heading}</h2>
        <div className="text">{parse(s9Text)}</div>
        <SignupButton externalSalesUrl={externalSalesUrl} />
      </div>
      <div className="background-image">
        <GatsbyImage
          image={s9BackgroundImage.localFile.childImageSharp.gatsbyImageData}
          alt={s9Heading}
          objectFit="cover"
        />
      </div>
    </Styling>
  );
}
