import gsap from "gsap";
import React from "react";

export default function SignupButton({ externalSalesUrl }) {
  const handleShowSignup = () => {
    const iframe = document.querySelector("#typeform");

    gsap.to(iframe, {
      duration: 0.5,
      autoAlpha: 1,
      pointerEvents: "all",
    });
  };

  return (
    <div
      onClick={() => handleShowSignup()}
      className="fakeButton button capsule_button red artist-development-signup-button">
      Sign up for a free consultation
    </div>
  );
}
