import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: rgba(0, 0, 0, 0.06);
  padding: 8rem 2rem;
  background: var(--black);
  color: var(--white);

  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem;
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      display: grid;
      column-gap: 4rem;
      grid-template-columns: auto 2fr;
    }
  }

  h2 {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    color: var(--white);
    letter-spacing: -0.07em;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 650px;
    margin-bottom: 4rem;
    position: relative;
    @media only screen and (min-width: 768px) {
      text-align: center;
    }

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
      transform: scaleX(-1) rotate(90deg);
      path {
        fill: var(--white);
      }
    }
  }

  .course-sections {
    list-style: decimal;
  }

  .left,
  .right {
    width: 100%;
  }

  .left {
    @media only screen and (min-width: 768px) {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      padding-right: 4rem;
    }

    section {
      margin-bottom: 4rem;
    }

    h3 {
      text-transform: uppercase;
      ${({ theme }) => theme.tomato("regular")}
      text-transform:uppercase;
      color: var(--white);
      letter-spacing: -0.07em;
      font-size: 2.6rem;
      line-height: 2%.6;
      margin: 0 auto 1rem auto;
      text-align: left;
      margin-bottom: 4rem;
      position: relative;
    }

    .course-sections {
      li {
        margin: 1rem;
      }
    }
  }

  .right {
    h2 {
      display: none;
    }

    .module {
      visibility: hidden;
      display: none;

      .gatsby-image-wrapper,
      video {
        width: 100%;
        margin-bottom: 4rem;
      }

      .text {
        margin-bottom: 4rem;

        ul {
          li {
            &:before {
              width: 2rem;
              height: 2rem;
              content: "";
              margin-right: 0.5rem;
              top: 5px;
              position: relative;
              background: url(${Arrow});
              background-size: contain;
              display: inline-block;
            }
          }
        }

        ul,
        ol,
        p {
          margin-bottom: 1rem;
          line-height: 1.5em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export { Styling };
