import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.div`
  width: 100%;
  display: flex;
  background: ${(props) =>
    props.darkLightMode === "dark" || props.darkLightMode === undefined
      ? "var(--black)"
      : "var(--white)"};
  color: ${(props) =>
    props.darkLightMode === "dark" || props.darkLightMode === undefined
      ? "var(--white)"
      : "var(--black)"};
  align-items: stretch;
  min-height: 72rem;
  position: relative;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media only screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }

  .video-wrapper {
    width: 100%;
    position: relative;
    min-height: 50vh;

    @media only screen and (min-width: 1024px) {
      width: 50%;
      height: 100%;
      min-height: auto;
      left: 0;
      display: block;
      position: absolute;
    }

    button {
      padding: 0;
      border: 0;
    }

    &:before {
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    }

    .play {
      ${({ theme }) => theme.easing("0.5")}
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      cursor: pointer;

      &.playing {
        transform: scale(3);
        opacity: 0;
      }

      &:hover {
        svg {
          path {
            fill: var(--black);
          }
        }
      }

      svg {
        z-index: 10;
        max-width: 100px;

        path {
          fill: var(--white);
          ${({ theme }) => theme.easing("0.5")}
        }
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: ${(props) => (props.introVideoObjectFit ? props.introVideoObjectFit : "cover")};
      display: flex;
    }
  }

  .info-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: 4rem 2rem;
    width: 100%;
    gap: 2rem;

    @media only screen and (min-width: 1024px) {
      width: 50%;
      padding: 0rem 4rem;
    }

    @media only screen and (min-width: 1350px) {
      padding: 0rem 8rem;
    }

    .info {
      position: relative;
      width: 100%;
      margin-top: auto;

      .main-title {
        text-transform: uppercase;
        ${({ theme }) => theme.tomato("regular")}
        text-transform:uppercase;
        font-size: clamp(5rem, 3.5vw, 12rem);
        letter-spacing: -0.07em;
        margin-bottom: 2rem;
        font-size: 3rem;
        line-height: 3rem;

        @media only screen and (min-width: 350px) {
          font-size: 4rem;
          line-height: 4rem;
          letter-spacing: -0.2rem;
        }

        @media only screen and (min-width: 420px) {
          font-size: 4rem;
          line-height: 4rem;
          letter-spacing: -0.3rem;
        }

        @media only screen and (min-width: 610px) {
          font-size: 5rem;
          line-height: 5rem;
          letter-spacing: -0.4rem;
        }

        @media only screen and (min-width: 800px) {
          font-size: 5rem;
          letter-spacing: -0.04em;
        }

        strong {
          ${({ theme }) => theme.tomato("bold")}
        }
      }

      .instructors,
      .course_duration {
        display: inline-flex;
        margin-right: 2rem;

        @media only screen and (max-width: 1024px) {
          margin-bottom: 4rem;
        }

        svg {
          margin-right: 0.4rem;
          margin-top: -0.1rem;
        }
      }
    }

    .intro-text {
      margin-bottom: 2rem;

      @media only screen and (max-width: 610px) {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        li {
          position: relative;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;

          &:before {
            width: 2rem;
            height: 2rem;
            content: "";
            margin-top: -0.2rem;
            margin-right: 1rem;
            position: relative;
            background: url(${Arrow});
            background-size: contain;
            background-color: var(--black);
          }
        }
      }
    }

    .logos {
      display: flex;
      justify-content: flex-start;
      gap: 2rem;
    }

    .buttons {
      /* align-self: flex-end; */
      text-align: left;
      width: 100%;
      margin-top: 2rem;

      @media only screen and (min-width: 1024px) {
        margin-top: auto;
      }

      @media only screen and (max-width: 610px) {
        margin-top: 0;
      }

      a,
      button {
        background: #d02600;
        color: var(--white);
        border: 1px solid #d02600 !important;
        cursor: pointer;
        /* color: ${(props) =>
          props.darkLightMode === "dark" || props.darkLightMode === undefined
            ? "var(--black)"
            : "var(--white)"};
        background: ${(props) =>
          props.darkLightMode === "dark" || props.darkLightMode === undefined
            ? "var(--white)"
            : "var(--black)"}; */

        @media only screen and (max-width: 550px) {
          width: 100%;
          text-align: center;
          justify-content: center;
          padding: 1.5rem;
        }

        &:hover {
          background: ${(props) =>
            props.darkLightMode === "dark" || props.darkLightMode === undefined
              ? "var(--black)"
              : "var(--white)"};
          color: ${(props) =>
            props.darkLightMode === "dark" || props.darkLightMode === undefined
              ? "var(--white)"
              : "var(--black)"};
          border-color: ${(props) =>
            props.darkLightMode === "dark" || props.darkLightMode === undefined
              ? "var(--white)"
              : "var(--black)"} !important;
        }
      }

      button:first-child {
        margin-right: 2rem;

        @media only screen and (max-width: 550px) {
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

export { Styling };
