import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.div`
  width: 100%;
  background: var(--white);
  padding: 8rem 2rem 4rem 2rem;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem 0 2rem;
  }

  .main-content {
    display: grid;
    width: 100%;
    gap: 2rem;

    .heading {
      text-transform: uppercase;
      ${({ theme }) => theme.tomato("regular")}
      text-transform:uppercase;
      /* font-size: clamp(5rem, 3.5vw, 12rem); */
      letter-spacing: -0.07em;
      margin: 0 auto 1rem auto;
      margin-bottom: 4rem;
      font-size: 4rem;
      line-height: 4rem;
      max-width: 650px;
      text-align: center;

      @media only screen and (max-width: 768px) {
        margin-bottom: 2rem;
      }

      strong {
        ${({ theme }) => theme.tomato("bold")}
      }
    }

    .text {
      width: 100%;
      margin-bottom: 4rem;
      text-align: center;

      @media only screen and (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }

    .list {
      margin: 0 auto;
      /* max-width: 1024px; */
      display: grid;
      margin-bottom: 4rem;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 2rem;
      text-align: center;

      @media only screen and (min-width: 768px) {
        gap: 4rem;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      }

      @media only screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        max-width: 1024px;
      }

      li {
        display: grid;
        gap: 2rem 4rem;

        @media only screen and (min-width: 768px) {
          gap: 4rem 8rem;
        }

        .icon {
          max-width: 75px;
          margin: 0 auto;

          @media only screen and (min-width: 768px) {
            margin: 0 auto 1rem auto;
          }
        }

        .list-text {
          width: 100%;
          display: grid;
          gap: 1rem;

          h3 {
            font-size: 2rem;
            text-transform: uppercase;
          }

          p {
            line-height: 1.5em;
          }
        }
      }
    }

    .text {
      width: 100%;
      display: grid;
      gap: 1rem;

      p {
        line-height: 1.5em;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;

        strong {
          font-weight: bold;
        }
      }
    }
  }
`;

export { Styling };
