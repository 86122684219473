import styled from "styled-components";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: var(--white);
  padding: 4rem 2rem;

  @media only screen and (min-width: 1024px) {
    padding: 8rem 2rem;
  }

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.06);
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: 768px) {
      gap: 4rem;
    }
  }

  .features {
    width: 100%;
    order: 2;

    @media only screen and (min-width: 768px) {
      order: 1;
    }
  }

  .swiper {
    overflow: visible;
    margin: 0 auto;

    @media only screen and (min-width: 1024px) {
      width: calc(100% - 12rem);
    }
  }

  .swiper-wrapper {
    @media only screen and (min-width: 768px) {
      align-items: center;
    }
  }

  .testimonials {
    margin: 8rem 0;
    position: relative;
    display: block;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  h2 {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    color: var(--black);
    /* font-size: clamp(5rem, 3.5vw, 12rem); */
    letter-spacing: -0.07em;
    /* margin-bottom: 2rem; */
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 650px;
    text-align: left;
    margin-bottom: 4rem;
    position: relative;

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      top: 5px; /* transform: rotate(90deg); */
      /* margin-left: -100%; */
      position: absolute;
      left: -40px;
      path {
        fill: var(--black);
      }
    }
  }

  /* .swiper * {
    pointer-events: none !important;
  } */

  .swiper-slide {
    opacity: 0 !important;
    pointer-events: none;
    transition: 0.3s opacity ease-in-out;
    gap: 4rem;
    color: var(--black);
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;

    @media only screen and (min-width: 768px) {
      gap: 8rem;
      grid-template-columns: 2fr 1fr;
    }

    svg {
      margin-bottom: 2rem;

      path {
        fill: var(--black);
      }
    }

    &.swiper-slide-active {
      opacity: 1 !important;
      pointer-events: all;

      .buttons {
        pointer-events: all !important;

        .arrow {
          z-index: 20;
          pointer-events: all !important;

          * {
            pointer-events: all !important;
          }
        }
      }
    }

    .swiper-button-disabled {
      opacity: 0.1;
      cursor: default;
    }

    .left {
      padding-left: 4rem;

      @media only screen and (max-width: 768px) {
        padding-right: 4rem;
      }

      .text {
        margin-bottom: 4rem;

        p {
          line-height: 2rem;
          font-size: 1.5rem;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .right {
      position: relative;
      @media only screen and (max-width: 768px) {
        order: 1;
      }
    }
    .left {
      @media only screen and (max-width: 768px) {
        order: 2;
      }
    }
  }

  .pagination-wrapper {
    order: 1;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 768px) {
      margin-top: 4rem;
      margin-bottom: 0;
      order: 2;
    }

    label {
      margin-left: 8rem;
      margin-bottom: 2rem;
      position: relative;
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.4rem;
    }
  }
  .pagination {
    display: inline-flex;
    justify-content: space-between;
    padding: 4rem;
    flex-wrap: wrap;
    gap: 2rem;
    background: var(--black);
    position: relative;
    border-radius: 10px;

    @media only screen and (min-width: 768px) {
      border-radius: 70px;
    }

    button {
      color: var(--black);
      border: 1px solid var(--white);
      background: white;
      border-radius: 30px;
      padding: 1.5rem 2rem;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 1.2rem;
      text-underline-offset: 5px;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      &.active {
        background: var(--black);
        border: 1px solid var(--white);
        color: var(--white);
        font-weight: bold;
      }
    }
  }
`;

export { Styling };
