import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import SignupButton from "../SignupButton.js";

export default function ArtistDevelopmentSection13({
  s13Heading,
  s13Preheading,
  s13Text,
  s13BackgroundImage,
  externalSalesUrl,
}) {
  return (
    <Styling>
      <div className="wrapper">
        <div className="preheading">{s13Preheading}</div>
        <h2 className="heading">{s13Heading}</h2>
        <div className="text">{parse(s13Text)}</div>
        <SignupButton externalSalesUrl={externalSalesUrl} />
      </div>
      <div className="background-image">
        <GatsbyImage
          image={s13BackgroundImage.localFile.childImageSharp.gatsbyImageData}
          alt={s13Heading}
          objectFit="cover"
        />
      </div>
    </Styling>
  );
}
