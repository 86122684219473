import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import Arrow from "../../../../images/arrow_right_black.inline.svg";

export default function ArtistDevelopmentSection10({ s10Heading, s10Repeater }) {
  return (
    <Styling>
      <div className="wrapper">
        <h2 className="heading">{s10Heading}</h2>

        <div className="info">
          {s10Repeater.map((info, index) => {
            return (
              <div className="info-item" key={index}>
                <div className="image">
                  <GatsbyImage
                    image={info.image.localFile.childImageSharp.gatsbyImageData}
                    alt={info.heading}
                    objectFit="cover"
                  />
                </div>
                <div className="detail">
                  <Arrow />
                  <h3>{info.heading}</h3>
                  <div className="text">{parse(info.text)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Styling>
  );
}
