import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import Arrow from "../../../../images/arrow_right_black.inline.svg";

export default function ArtistDevelopmentSection12({ s12Heading, s12FaqsRepeater }) {
  return (
    <Styling>
      <div className="wrapper">
        <h2 className="heading">{s12Heading}</h2>

        <div className="faqs">
          {s12FaqsRepeater.map((faq, index) => {
            return (
              <div className="faq" key={index}>
                <div className="col-1">
                  <Arrow />
                  <h3 className="question">{faq.question}</h3>
                </div>
                <div className="answer">{parse(faq.answer)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Styling>
  );
}
