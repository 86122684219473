import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Arrow from "../../../../images/arrow_right_black.inline.svg";
import parse from "html-react-parser";
import gsap from "gsap";
import SignupButton from "../SignupButton.js";

export default function ArtistDevelopmentSection6({
  s6Heading,
  s6CourseContentRepeater,
  s6DisableContentToggling,
  externalSalesUrl,
}) {
  const [currentItem, setCurrentItem] = useState({ section: 0, module: 0 });

  const handleClick = (e) => {
    const target = e.target.closest(".section");

    if (s6DisableContentToggling) return;

    setCurrentItem({ section: target.dataset.section, module: target.dataset.module });
  };
  const handleMouseOver = (e) => {
    if (s6DisableContentToggling) return;

    const target = e.target.closest(".section");
    if (
      target.dataset.section === currentItem.section &&
      target.dataset.module === currentItem.module
    )
      return;

    gsap.to(target, { opacity: 1, duration: 0.5 });
  };
  const handleMouseLeave = (e) => {
    if (s6DisableContentToggling) return;

    const target = e.target.closest(".section");
    if (
      target.dataset.section === currentItem.section &&
      target.dataset.module === currentItem.module
    )
      return;
    gsap.to(target, { opacity: 0.5, duration: 0.5 });
  };

  useEffect(() => {
    const modules = document.querySelectorAll(".module");
    const triggers = document.querySelectorAll(".section");
    const tl = gsap.timeline();

    tl.to(modules, { autoAlpha: 0, duration: 0.5, display: "none" }).to(
      `.module[data-section="${currentItem.section}"][data-module="${currentItem.module}"]`,
      {
        autoAlpha: 1,
        display: "block",
        duration: 0.5,
      }
    );

    if (s6DisableContentToggling) return;
    tl.to(triggers, { opacity: 0.5, fontWeight: "normal", duration: 0.5 }).to(
      `.section[data-section="${currentItem.section}"][data-module="${currentItem.module}"]`,
      {
        opacity: 1,
        fontWeight: "bold",
        duration: 0.5,
      }
    );
  }, [currentItem]);

  return (
    <Styling>
      <h2 className="heading">
        {s6Heading} <Arrow />
      </h2>
      <div className="wrapper">
        <aside className="left">
          {s6CourseContentRepeater.map((content, i) => {
            return (
              <section>
                <h3>{content.courseSectionTitle}</h3>
                <ol className="course-sections">
                  {s6CourseContentRepeater[i].courseSection.map((section, index) => {
                    return (
                      <li
                        className="section"
                        onClick={(e) => handleClick(e)}
                        onMouseEnter={(e) => handleMouseOver(e)}
                        onMouseLeave={(e) => handleMouseLeave(e)}
                        data-content={`module_${index}`}
                        data-section={i}
                        data-module={index}>
                        <h4>{section.courseModuleTitle}</h4>
                      </li>
                    );
                  })}
                </ol>
              </section>
            );
          })}
        </aside>

        <main className="right">
          {s6CourseContentRepeater.map((content, i) => {
            return s6CourseContentRepeater[i].courseSection.map((section, index) => {
              return (
                <section
                  className="module"
                  data-content={`module_${index}`}
                  data-section={i}
                  data-module={index}>
                  <h2>{section.courseModuleTitle}</h2>
                  {section.courseModuleImage && (
                    <GatsbyImage
                      image={getImage(section.courseModuleImage.localFile)}
                      alt={section.courseModuleTitle}
                    />
                  )}
                  {section.courseModuleVideo && <video src={section.courseModuleVideo}></video>}
                  <div className="text">{parse(section.courseModuleText)}</div>
                  <SignupButton externalSalesUrl={externalSalesUrl} />
                </section>
              );
            });
          })}
        </main>
      </div>
    </Styling>
  );
}
