import React, { useEffect, useRef } from "react";

export default function TrustBox() {
  const trustBoxRef = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, []);

  return (
    <div
      ref={trustBoxRef}
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="60e32daf8e301d0001f1c936"
      data-style-height="100px"
      data-style-width="120px"
      data-theme="dark"
      className="trustpilot-widget" // Renamed this to className.
    >
      <a
        href="https://www.trustpilot.com/review/example.com"
        target="_blank"
        rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
}
