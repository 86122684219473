import styled from "styled-components";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: var(--black);
  padding: 8rem 2rem;

  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem;
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
  }

  .testimonials {
    margin: 8rem 0;
    position: relative;
    display: block;

    @media only screen and (max-width: 768px) {
      margin: 4rem 0;
    }
  }

  .heading {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    color: var(--white);
    /* font-size: clamp(5rem, 3.5vw, 12rem); */
    letter-spacing: -0.07em;
    /* margin-bottom: 2rem; */
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 650px;
    text-align: center;
    margin-bottom: 4rem;

    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      transform: rotate(90deg);

      path {
        fill: var(--white);
      }
    }
  }

  .swiper * {
    pointer-events: none !important;
  }

  .swiper-slide {
    opacity: 0 !important;
    pointer-events: none;
    transition: 0.3s opacity ease-in-out;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    color: var(--white);
    align-items: center;

    @media only screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8rem;
    }

    svg {
      margin-bottom: 2rem;

      path {
        fill: var(--white);
      }
    }

    &.swiper-slide-active {
      opacity: 1 !important;
      pointer-events: all;

      .buttons {
        pointer-events: all !important;

        .arrow {
          z-index: 20;
          pointer-events: all !important;

          * {
            pointer-events: all !important;
          }
        }
      }
    }

    .swiper-button-disabled {
      opacity: 0.1;
      cursor: default;
    }

    .quote {
      font-size: 1.6rem;
      line-height: 1.5em;
      margin-bottom: 2rem;
    }

    .person {
      font-size: 1.6rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 4rem;
    }

    .buttons {
      display: inline-flex;

      .arrow {
        border: 1px solid
          ${(props) =>
            props.darkLightMode === "light" || props.darkLightMode === undefined
              ? "var(--black)"
              : "var(--white)"};
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 1rem;

        ${({ theme }) => theme.easing("0.5")}

        &.next {
          margin-right: 4rem;
        }

        svg {
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          ${({ theme }) => theme.easing("0.5")}
          position: relative;
          path {
            ${({ theme }) => theme.easing("0.5")}
            fill: var(--white);
          }
        }

        &:hover {
          background: var(--white);
          svg path {
            fill: var(--black);
          }
        }
      }
    }

    .left {
      order: 2;
      @media only screen and (min-width: 768px) {
        order: 1;
      }
    }

    .right {
      position: relative;
      order: 1;
      @media only screen and (min-width: 768px) {
        order: 2;
      }

      .play {
        position: absolute;
        width: 90px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        ${({ theme }) => theme.easing("0.5")}
      }

      &:hover {
        .play {
          transform: scale(1.1) translate(-50%, -50%);
        }
      }
    }
  }

  .pagination {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
    }

    button {
      color: var(--white);
      border: 0;
      background: transparent;
      padding: 0;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 1.2rem;
      text-underline-offset: 5px;

      &.active {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
`;

export { Styling };
