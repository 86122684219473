import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: var(--white);
  padding: 4rem 2rem;

  @media only screen and (min-width: 1024px) {
    padding: 8rem 2rem;
  }

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.06);
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  h2 {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    letter-spacing: -0.07em;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 950px;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
      transform: scaleX(-1) rotate(90deg);
      path {
        fill: var(--white);
      }
    }
  }

  .faqs {
    display: grid;
    gap: 4rem;
  }

  .faq {
    display: grid;
    column-gap: 4rem;
    margin: 0 auto;
    padding: 4rem 0 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    grid-template-columns: 1fr;

    @media only screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .col-1 {
      display: grid;
      gap: 2rem;
      grid-template-columns: 20px 1fr;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .question {
      text-transform: uppercase;
      ${({ theme }) => theme.tomato("regular")}
      text-transform:uppercase;
      letter-spacing: -0.07em;
      font-size: 1.8rem;
      line-height: 2rem;
      max-width: 450px;
      text-align: left;
      margin-bottom: 2rem;
      position: relative;

      strong {
        ${({ theme }) => theme.tomato("bold")}
      }
    }
    .answer {
      p {
        line-height: 1.5em;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export { Styling };
