import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";

export default function ArtistDevelopmentSection3({ s3Heading, s3IconsTitles, s3Text }) {
  return (
    <Styling>
      <div className="main-content">
        <h2 className="heading">{s3Heading}</h2>
        <ul className="list">
          {s3IconsTitles.map((item) => (
            <li>
              <div className="icon">
                <GatsbyImage image={item.icon.localFile.childImageSharp.gatsbyImageData} />
              </div>
              <div className="list-text">
                <h3>{item.title}</h3>
              </div>
            </li>
          ))}
        </ul>
        <div className="text">{parse(s3Text)}</div>
      </div>
    </Styling>
  );
}
