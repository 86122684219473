import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: rgba(0, 0, 0, 0.06);
  padding: 8rem 2rem;
  background: var(--black);
  color: var(--white);

  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem;
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    /* padding: 0 2rem; */
    /* display: grid;
    column-gap: 4rem;
    grid-template-columns: auto 2fr; */
  }

  h2 {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    letter-spacing: -0.07em;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 950px;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
      transform: scaleX(-1) rotate(90deg);
      path {
        fill: var(--white);
      }
    }
  }
  .list {
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 2rem;
    text-align: center;

    @media only screen and (min-width: 768px) {
      gap: 4rem;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
      max-width: 1024px;
    }

    li {
      display: grid;
      gap: 2rem 4rem;

      @media only screen and (min-width: 768px) {
        gap: 4rem 8rem;
      }

      .icon {
        max-width: 75px;
        margin: 0 auto;

        @media only screen and (min-width: 768px) {
          margin: 0 auto 1rem auto;
        }

        img {
          filter: invert(1);
        }
      }

      .text {
        width: 100%;
        display: grid;
        gap: 1rem;

        h3 {
          font-size: 2.4rem;
          text-transform: uppercase;
          text-align: center;
        }

        p {
          line-height: 1.5em;
        }
      }
    }
  }
`;

export { Styling };
