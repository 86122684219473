import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";

export default function ArtistDevelopmentSection8({ s8Heading, s8IconsTitles }) {
  return (
    <Styling>
      <h2 className="heading">{s8Heading}</h2>
      <div className="wrapper">
        <ul className="list">
          {s8IconsTitles.map((item) => (
            <li>
              <div className="icon">
                <GatsbyImage image={item.icon.localFile.childImageSharp.gatsbyImageData} />
              </div>
              <div className="text">
                <h3>{item.title}</h3>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Styling>
  );
}
