import styled from "styled-components";
import Arrow from "../../../../images/arrow_bullet.svg";

const Styling = styled.section`
  width: 100vw;
  position: relative;
  background: var(--white);
  padding: 8rem 2rem;

  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem;
  }

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.06);
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    /* padding: 0 2rem; */
    /* display: grid;
    column-gap: 4rem;
    grid-template-columns: auto 2fr; */
  }

  h2 {
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    letter-spacing: -0.07em;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem auto;
    max-width: 650px;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;

    strong {
      ${({ theme }) => theme.tomato("bold")}
    }

    svg {
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
      transform: scaleX(-1) rotate(90deg);
      path {
        fill: var(--white);
      }
    }
  }

  .videos {
    display: grid;
    column-gap: 2rem;
    row-gap: 4rem;
    margin: 0 auto;
    grid-template-columns: 1fr;
    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    video {
      width: 100%;
      margin-bottom: 1rem;
    }

    h3 {
      font-weight: bold;
      margin-bottom: 1rem;
      text-transform: uppercase;
      line-height: 1.5em;
    }

    .text-content {
      p {
        margin-bottom: 1rem;
        line-height: 1.5em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export { Styling };
